import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useStatusDayStore } from '@/Store/StatusDayStore'
import { useLikedStore } from '@/Store/LikedStore'
import { useOfficeStore } from '@/Store/OfficeStore'
import { useUserStore } from '@/Store/UserStore'
import { useBankHolidaysStore } from '@/Store/BankHolidaysStore'

export const useAppStore = defineStore('app', () => {
  const isLoading = ref(true)

  const bootApp = async () => {
    isLoading.value = true
    return Promise.all([
      useLikedStore().fetchLiked(),
      useStatusDayStore().fetchStatusDays(),
      useOfficeStore().loadOffices()
    ]).finally(() => {
      useBankHolidaysStore().loadBankHolidays(useUserStore().user)
      isLoading.value = false
    })
  }

  return {
    bootApp,
    isLoading
  }
})
