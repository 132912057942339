import { createI18n } from 'vue-i18n'

const instance = createI18n({
  legacy: false,
  warnHtmlMessage: false,
  locale: 'fr'
})

export const availableLanguages = ['fr', 'en', 'de', 'es', 'it']

export default instance

const loadedLanguages: string[] = []

export async function setLocale(locale: string) {
  if (!availableLanguages.includes(locale)) {
    locale = 'fr'
  }
  await loadLocaleMessages(locale)
  instance.global.locale.value = locale
  document.querySelector('html')?.setAttribute('lang', locale)
}

async function loadLocaleMessages(locale: string) {
  if (loadedLanguages.includes(locale)) {
    return
  }

  const messages = (await import(`../locales/${locale}.json`)).default
  instance.global.setLocaleMessage(locale, messages)
  loadedLanguages.push(locale)
}

export const i18n = instance.global
export const { t } = instance.global
