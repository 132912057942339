import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useListBankHolidays } from '@/Query/userQuery'
import type UserBackend from '@/Backend/models/UserBackend'
import type BankHolidayBackend from '@/Backend/models/BankHolidayBackend'

export const useBankHolidaysStore = defineStore('bank_holidays', () => {
  const bankHolidays = ref<BankHolidayBackend[]>([])

  const loadBankHolidays = async (user: UserBackend | null | undefined) => {
    if (!user) return
    useListBankHolidays(user.id).then((resp) => {
      bankHolidays.value = (resp.data.value as BankHolidayBackend[]) ?? []
    })
  }

  const getBankHoliday = (date: string) => {
    return bankHolidays.value.find((holiday) => holiday.date === date)?.summary || null
  }

  return {
    bankHolidays,
    loadBankHolidays,
    getBankHoliday
  }
})
