import { defineStore } from 'pinia'
import { ref } from 'vue'
import { i18n } from '@/i18n'
import PolymorphismTypeEnum from '@/Enums/PolymorphismTypeEnum'
import notify from '@/notify'
import { useDeleteLike, useListLikes, useStoreLike } from '@/Query/likeQuery'

const { execute: storeLike } = useStoreLike()
const { execute: deleteLike } = useDeleteLike()

export const useLikedStore = defineStore('liked', () => {
  const { t } = i18n
  type likeableType =
    | PolymorphismTypeEnum.USER
    | PolymorphismTypeEnum.OFFICE
    | PolymorphismTypeEnum.MEETING_ROOM
    | PolymorphismTypeEnum.ZONE
  type likedType = {
    [key in likeableType]: number[]
  }
  const liked = ref<likedType | null>(null)
  const isLoading = ref(true)

  const defaultState: likedType = {
    [PolymorphismTypeEnum.USER]: [] as number[],
    [PolymorphismTypeEnum.OFFICE]: [] as number[],
    [PolymorphismTypeEnum.MEETING_ROOM]: [] as number[],
    [PolymorphismTypeEnum.ZONE]: [] as number[]
  }

  async function fetchLiked() {
    if (!liked.value) {
      useListLikes().then(({ data }) => {
        liked.value = {
          ...defaultState,
          ...data.value
        }
        isLoading.value = false
      })
    }
  }

  function like(id: number, likeable_type: likeableType) {
    storeLike({
      data: {
        likeable_id: id,
        likeable_type: likeable_type
      }
    }).then(({ data }) => {
      if (liked.value && data.value) {
        liked.value[likeable_type].push(data.value.data.id)
      }
      notify({
        type: 'success',
        message: t('days.calendar.card.success_like', { user: data.value?.data.name }),
        duration: 2500
      })
    })
  }

  function unlike(id: number, likeable_type: likeableType) {
    deleteLike({
      data: {
        likeable_id: id,
        likeable_type: likeable_type
      }
    }).then(({ data }) => {
      if (liked.value && data.value) {
        liked.value[likeable_type].splice(liked.value[likeable_type].indexOf(data.value.data.id), 1)
      }
      notify({
        type: 'success',
        message: t('days.calendar.card.success_unlike', { user: data.value?.data.name }),
        duration: 2500
      })
    })
  }

  return { liked, fetchLiked, like, unlike, isLoading }
})
