import { route } from 'ziggy-js'
import useAxios from '@/useAxios'
import type OfficeBackend from '@/Backend/models/OfficeBackend'
import type { UseAxiosOptions } from '@vueuse/integrations'

export function getOffice<T = { data: OfficeBackend }>(
  officeId: number,
  params: {} | undefined = undefined,
  options: UseAxiosOptions<T> | undefined = undefined
) {
  return useAxios<T>(
    route('api.v1.offices.get', officeId),
    {
      method: 'get',
      params
    },
    options
  )
}

export function useStoreOffice<E = { [k: string]: string }>() {
  return useAxios<{ data: OfficeBackend }, E>(
    route('api.v1.offices.store'),
    {
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' }
    },
    { immediate: false }
  )
}

export function useDeleteOffice(officeId: number) {
  return useAxios(
    route('api.v1.offices.destroy', officeId),
    {
      method: 'delete'
    },
    { immediate: false }
  )
}

export function useUpdateOffice(officeId: number, data = {}) {
  return useAxios<{ data: OfficeBackend }>(
    route('api.v1.offices.update', officeId),
    {
      method: 'put',
      data: data
    },
    { immediate: false }
  )
}
export function useUpdateOfficePhoto(officeId: number, data = {}) {
  return useAxios(
    route('api.v1.offices.photo.update', officeId),
    {
      method: 'post',
      data: data
    },
    { immediate: false }
  )
}

export function useListOffices<T = { data: OfficeBackend[] }>(
  params: {} | undefined = undefined,
  options: UseAxiosOptions<T> | undefined = undefined
) {
  return useAxios<T>(
    route('api.v1.offices.list'),
    {
      method: 'get',
      params: params
    },
    {
      immediate: true,
      ...options
    }
  )
}
