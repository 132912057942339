import { ElNotification } from 'element-plus'
import type { NotificationProps } from 'element-plus'
import 'element-plus/theme-chalk/el-notification.css'

export default function notify(options?: Partial<NotificationProps>) {
  ElNotification({
    ...options,
    duration: 2500
  })
}
