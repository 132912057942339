import { defineStore } from 'pinia'
import { ref } from 'vue'
import type OfficeBackend from '@/Backend/models/OfficeBackend'
import { useListOffices } from '@/Query/officeQuery'

export const useOfficeStore = defineStore('offices', () => {
  const offices = ref<OfficeBackend[]>([])
  const loadOffices = async () => {
    useListOffices({
      includes: ['zones']
    }).then((resp) => {
      offices.value = resp.data.value?.data ?? []
    })
  }
  return {
    offices,
    loadOffices
  }
})
