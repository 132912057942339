const enum PolymorphismTypeEnum {
  OFFICE = 'office',
  ZONE = 'zone',
  USER = 'user',
  TEAM = 'team',
  LOCATION = 'location',
  COMPANY_CONTRACT = 'company_contract',
  PARKING = 'parking',
  COMPANY = 'company',
  MEETING_ROOM = 'meeting_room'
}

export default PolymorphismTypeEnum
