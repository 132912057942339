import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/axios'
import type StatusDayBackend from '@/Backend/models/StatusDayBackend'
import { route } from 'ziggy-js'

export const useStatusDayStore = defineStore('statusDays', () => {
  const statusDays = ref<StatusDayBackend[]>([])
  const statusDaysById = ref<{
    [n: number]: StatusDayBackend
    null: StatusDayBackend
  } | null>(null)
  const statusDaysByKey = ref<{
    [n: string]: StatusDayBackend
  }>({})
  const isLoading = ref(true)

  async function fetchStatusDays() {
    if (!statusDaysById.value) {
      return fetch()
    }
  }

  async function refreshStatusDays() {
    await fetch()
  }

  async function fetch() {
    return axios.get(route('api.v1.status-days.list')).then(({ data }) => {
      statusDays.value = data.data
      statusDaysByKey.value = data.data.reduce(
        (
          acc: {
            [n: string]: StatusDayBackend
          },
          status: StatusDayBackend
        ) => {
          acc[status.key] = status
          return acc
        },
        {}
      )
      statusDaysById.value = data.data.reduce(
        (
          acc: {
            [n: number]: StatusDayBackend
          },
          status: StatusDayBackend
        ) => {
          acc[status.id] = status
          return acc
        },
        {}
      )
      isLoading.value = false
    })
  }

  return {
    statusDaysById,
    statusDaysByKey,
    statusDays,
    isLoading,
    fetchStatusDays,
    refreshStatusDays
  }
})
