import { route } from 'ziggy-js'
import useAxios from '@/useAxios'
import type DefaultWeekBackend from '@/Backend/models/DefaultWeekBackend'
import type ZoneBackend from '@/Backend/models/ZoneBackend'
import type { UseAxiosOptions } from '@vueuse/integrations'
import type { AxiosRequestConfig } from 'axios'

export function useGetDefaultWeek<T = { data: DefaultWeekBackend }>(
  config: AxiosRequestConfig = {},
  options: UseAxiosOptions<T> | undefined = undefined
) {
  return useAxios<T>(route('api.v1.default-weeks.get'), config, options)
}

export function useUpdateDefaultWeek(defaultWeekId: number) {
  return useAxios(
    route('api.v1.default-weeks.update', { defaultWeek: defaultWeekId }),
    {
      method: 'put'
    },
    {
      immediate: false
    }
  )
}
